/* eslint-disable no-unused-vars */
<template>
  <!----->
  <div>
    <bfc-bar></bfc-bar>
    <lab-menu></lab-menu>
    <v-dialog v-model="uploading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Uploading
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div style="height: 20px"></div>
    <v-app-bar color="gray" style="height: 48px">
      <v-breadcrumbs :items="breadcrumbs" style="margin-top: -16px">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-spacer></v-spacer>
    </v-app-bar>
    <div style="height: 20px"></div>

    <v-stepper v-model="e1">
      <v-stepper-header>
        <template>
          <!--v-stepper-step :key="`${n}-step`" :complete="e1 > n" :step="n">
            Step {{ n }}
          </!--v-stepper-step-->

          <v-stepper-step editable step="1" key="1-step"> Setting Step </v-stepper-step>

          <v-divider></v-divider>
          <v-stepper-step editable step="2" key="2-step"> Lab Upload </v-stepper-step>

          <!--v-divider></!--v-divider>
          <v-stepper-step editable step="3"> Lab Upload </v-stepper-step>

          <v-divider></v-divider>
          <v-stepper-step editable step="4"> Package </v-stepper-step>
          -->
        </template>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content key="1-content" step="1">
          <v-card>
            <v-card-title> Setting Step </v-card-title>
            <v-card-text>
              <v-container fluid>
                <v-row>
                  <v-col md="12" sm="12">
                    <v-text-field
                      label="How many step"
                      v-model.number="totalStep"
                      id="totalStep"
                      required
                      :rules="requiredRules"
                      type="Number"
                    >
                    </v-text-field>
                    <v-text-field
                      label="Set uploa d step to complete"
                      v-model="stepToComplete"
                      id="stepToComplete"
                      placeholder="ex. 1,3,5"
                      required
                      :rules="requiredRules"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" @click="nextStep(1)"> Next </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <!-- Lab Upload -->
        <v-stepper-content key="2-content" step="2">
          <div fuild v-if="eForm.totalStep">
            <v-btn-toggle v-model="toggle_exclusive" mandatory>
              <v-btn
                v-for="(item, index) in aligner_toggle"
                :key="index"
                :value="index"
                color="blue lighten-4"
              >
                {{ item }}
              </v-btn>
            </v-btn-toggle>
          </div>
          <div fuild>
            <div v-for="(item, index) in aligner_toggle" :key="index" :value="index">
              <div v-if="toggle_exclusive == index && totalStep > 0">
                <dragdrop-upload
                  v-bind:uploadName="index"
                  v-bind:uploadId="index"
                  v-bind:totalStep="totalStep"
                  @updateUpload="updateUpload"
                  @removeUpload="removeLabUpload"
                  v-bind:imgData="eForm.labUpload[index]"
                ></dragdrop-upload>
              </div>
            </div>

            <v-row>
              <v-col></v-col>
            </v-row>
            <v-row>
              <v-col> <v-divider></v-divider></v-col>
            </v-row>
            <v-row>
              <v-col md="12" sm="12">
                <!--v-btn color="primary" @click="saveLabUpload"> Save Upload </!--v-btn-->

                <v-btn color="cyan lighten-4" @click="backStep(1)"> Back </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Loading
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import lapUpload from "../../library/lab-upload/lab-upload";
//import paymentComponent from "../payment/payment";
import SystemBar from "../../library/system-bar/system-bar";
import LabMenu from "../menubar/menubar";
import axios from "axios";

import { bfcPackage } from "../../config/config.js";

export default {
  components: {
    "dragdrop-upload": lapUpload,
    "lab-menu": LabMenu,
    "bfc-bar": SystemBar,
  },
  data: () => {
    return {
      toggle_exclusive: 0,
      aligner_toggle: {
        //rightProfile: "Right Profile",
        //frontalRepose: "Frontal Repose",
        //frontalSmiling: "Frontal Smiling",
        anteriorFrontal: "Anterior Frontal",
        buccalRight: "Buccal Right",
        buccalLeft: "Buccal Left",
        occlusalUpper: "Occlusal Upper",
        occlusalLower: "Occlusal Lower",
      },

      loading: true,
      uploading: false,
      packages: bfcPackage,
      hasError: false,
      caseId: "",
      caseNo: "",
      custId: "",
      doctorId: "",
      clinicId: "",
      url: process.env.VUE_APP_LOYALTY_API_URI,
      actionCase: "Create Draft",

      rightProfile: "Right Profile",
      frontalRepose: "Frontal Repose",
      frontalSmiling: "Frontal Smiling",
      anteriorFrontal: "Anterior Frontal",
      buccalRight: "Buccal Right",
      buccalLeft: "Buccal Left",
      occlusalUpper: "Occlusal Upper",
      occlusalLower: "Occlusal Lower",
      // opg: "OPG",
      // latCeph: "Lat Ceph",
      // others: "Others",
      // others2: "Others 2",
      caseFormDental: [],

      e1: 1,
      nSteps: 2,
      teeth2extract_cbx: [],
      breadcrumbs: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/lab/dashboard",
        },
        {
          text: "Lab Upload",
          disabled: true,
          href: "/lab/",
        },
      ],
      eForm: {
        labUpload: {
          //  rightProfile: {},
          //  frontalRepose: {},
          //  frontalSmiling: {},
          anteriorFrontal: {},
          buccalRight: {},
          buccalLeft: {},
          occlusalUpper: {},
          occlusalLower: {},
        },
        totalStep: 0,
        stepToComplete: "",
      },
      labUpload: {
        // rightProfile: {},
        // frontalRepose: {},
        // frontalSmiling: {},
        anteriorFrontal: {},
        buccalRight: {},
        buccalLeft: {},
        occlusalUpper: {},
        occlusalLower: {},
      },
      tmpLabUpload: {},
      totalStep: 0,
      stepToComplete: "",
      requiredRules: [(v) => !!v || "This field is required"],
      uploadObject: {},
      caseInfo: {},
    };
  },
  created() {},
  mounted() {
    this.breadcrumbs.push({
      text: "Case : " + this.$route.params.caseNo,
      disabled: true,
      href: "#",
    });
    //this.loading = true;
    // console.log(this.loading);
    this.init();
    // console.log(this.loading);
    // this.packages = bfcPackage;
    // console.log(this.packages);
  },
  computed: {
    ...mapGetters({
      //getAuth: "BFC/getAuth",
      //getDoctorInfo: "BFC/getDoctorInfo",
      //getCaseInfo: "BFC/getCaseInfo",
    }),
    info() {
      //console.log(this.$route.params.caseNo);
      return this.getCaseInfo(this.$route.params.caseNo);
    },
    caseData() {
      return this.eForm;
    },
    requiredFields() {
      return {
        totalStep: this.totalStep,
        stepToComplete: this.stepToComplete,
      };
    },
  },
  methods: {
    ...mapActions({
      signout: "BFC/Logout",
      ///createDraft: "BFC/createDraftCase",
      //removeImage: "BFC/removeDoctorDraftImage",
    }),
    async DraftStep(n) {
      // check require
      if (n === this.nSteps) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
      }
    },
    async toPayment(n) {
      if (n === this.nSteps) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
      }
    },
    async nextStep(n) {
      //console.log("nextStep");
      let breakForEachException = {};
      try {
        await Object.keys(this.requiredFields).forEach((f) => {
          //console.log(f);
          console.log(this.requiredFields[f]);
          if (!this.requiredFields[f]) {
            this.hasError = true;
            document.getElementById(f).focus();
            throw breakForEachException;
          }
        });
        //this.loading = true;
        //console.log(n);
        if (n === this.nSteps) {
          this.e1 = 1;
        } else {
          this.e1 = n + 1;
        }
      } catch (e) {
        console.log(e);
        if (e != breakForEachException) throw e;
      }
    },
    backStep(n) {
      if (n === 0) {
        this.e1 = 1;
      } else {
        this.e1 = n;
      }
    },
    async prepareImageData() {
      let labUpload = {};

      // eslint-disable-next-line no-unused-vars
      // console.log(this.labUpload);
      // console.log(this.eForm.labUpload);
      if (typeof this.tmpLabUpload === "undefined") {
        //this.eForm.labUpload = [];
      }
      for (let key of Object.keys(this.labUpload)) {
        labUpload[key] = {};
        // console.log("length");
        // console.log(typeof this.tmpLabUpload.length);
        // console.log(this.tmpLabUpload);
        for (let i = 1; i <= this.totalStep; i++) {
          labUpload[key][i] = this.tmpLabUpload
            .filter((item) => {
              // eslint-disable-next-line no-unused-vars

              return item.Type == key && i == item.Step;
            })
            .map((item2Map) => {
              return {
                FileName: item2Map.FileName,
                url: this.url + "/public/cases/" + item2Map.url,
              };
              //}
              //return obj2;
            });
          if (labUpload[key][i].length == 0) {
            labUpload[key][i] = [{ FileName: "", url: "" }];
          }
        }
      }
      // console.log(labUpload);
      this.eForm.labUpload = labUpload;
      // console.log(this.eForm.labUpload);
      // console.log(this.tmpLabUpload);
    },
    async init() {
      console.log("call init");
      try {
        //let payload
        await axios
          .post(
            this.url + "/api/bfc/v1/case/caseInfo",
            { caseNo: this.$route.params.caseNo }, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            try {
              if (response.data.statusCode == 0) {
                let info = response.data.data[0];
                this.caseId = info._id;
                this.doctorId = info.doctorId;
                this.custId = info.custId;

                let stepToComplete =
                  this.requiredFields.stepToComplete || info.stepToComplete;
                this.stepToComplete = stepToComplete;

                let totalStep = this.requiredFields.totalStep || info.totalStep;
                this.totalStep = totalStep;
                this.tmpLabUpload = info.labUpload;

                this.eForm = info;
                this.loading = false;

                // console.log(this.eForm);
              } else {
                // show error
                console.log("error response");
              }
            } catch (err) {
              console.log("error try response");
              console.log(err);
            }
          });
      } catch (e) {
        //
        console.log(e);
      }
    },
    logout() {
      this.signout();
      this.$router.push("/lab");
    },

    updateUpload(val) {
      //console.log(val);
      this.uploadObject = {};

      this.uploadObject[val.id] = {};
      this.uploadObject[val.id][val.index] = val.file;
      //console.log(this.uploadObject);
      this.uploading = true;
      this.instantUpload();
    },
    async removeLabUpload(val) {
      //console.log(val);
      this.eForm.labUpload[val.id][val.index] = [
        {
          FileName: "",
          FileType: "",
          FileValue: "",
          Url: "",
        },
      ];
      let removeImg = {
        Type: val.id,
        Step: val.index.toString(),
      };
      try {
        const payload = {
          //
          caseId: this.caseId,
          removeData: removeImg,
        };
        //console.log(payload);

        await axios
          .post(
            this.url + "/api/bfc/v1/lab/removeImageMonitoring",
            // need to change
            payload,
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            //console.log(response);
            if (response.data.statusCode == 0) {
              alert("Saved!");
            } else {
              // show error
              console.log("error response");
            }
          });
      } catch (e) {
        console.log(e);
      }
      this.uploading = false;
    },

    async instantUpload() {
      try {
        let payload = {
          caseId: this.caseId,
          path: this.$route.params.caseNo + "/lab-upload",
          case: {
            totalStep: this.totalStep,
            stepToComplete: this.stepToComplete,
          },
          labUploadData: this.uploadObject,
        };
        //let hasError = false;
        await axios
          .post(
            this.url + "/api/bfc/v1/lab/uploadOneMonitoring",
            // need to change
            payload,
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            //console.log(response);
            if (response.data.statusCode == 0) {
              alert("Saved!");
            } else {
              // show error
              console.log("error response");
            }
          });
      } catch (e) {
        //

        console.log(e);
      }
      this.uploading = false;
    },

    //----------------------
    async saveLabUpload() {
      try {
        let payload = {
          caseId: this.caseId,
          path: this.$route.params.caseNo + "/lab-upload",
          case: {
            totalStep: this.totalStep,
            stepToComplete: this.stepToComplete,
          },
          labUploadData: this.eForm.labUpload,
        };
        await axios
          .post(
            this.url + "/api/bfc/v1/lab/uploadMonitoring",
            // need to change
            payload,
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            //console.log(response);
            if (response.data.statusCode == 0) {
              alert("Saved!");
            } else {
              // show error
              console.log("error response");
            }
          });
      } catch (e) {
        //

        console.log(e);
      }
    },
  },
  watch: {
    caseData() {
      console.log("--------------------");
    },
    totalStep: {
      handler(val) {
        console.log("totalStep " + val);
        if (val > 0) {
          this.eForm.totalStep = val;
          this.prepareImageData();
        }
      },
    },
    stepToComplete: {
      handler(val) {
        console.log("stepToComplete " + val);
        if (val > 0) {
          this.eForm.stepToComplete = val;
          // this.prepareImageData();
        }
      },
    },
  },
};
</script>
<style scope type="css">
.bfc_li_teeth2extract,
.bfc_li_teeth2extract li {
  font-size: 0.97em;
  display: inline;
}

.bfc_li_teeth2extract_cbx {
  margin-left: 5.4px;
}

.bfc_li_RelieveCrowdingByStripping {
  display: inline;
  margin-left: 175px;
}

.bfc_li_RelieveCrowdingByStripping.mandible {
  display: inline;
  margin-left: 210px;
}

.bfc_li_RelieveCrowdingByStripping li {
  font-size: 0.97em;
  display: inline;
}

.bfc_li_RelieveCrowdingByStripping_cbx {
  /*padding-left: 20px;*/
}
.padding14 {
  padding-left: 14px;
}
.padding16 {
  padding-left: 16px;
}

.padding19 {
  padding-left: 19px;
}

.padding18 {
  padding-left: 19px;
}

.padding22 {
  padding-left: 22px;
}

.padding24 {
  padding-left: 24px;
}

.padding26 {
  padding-left: 26px;
}

.padding30 {
  padding-left: 30px;
}

/************/
.attachment {
  position: relative !important;
  display: block !important;
  width: 220px !important;
  height: auto;
  float: left;
}
.attachment ul {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  list-style-position: outside;
  float: left;
  margin-block-start: em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

.attachment li {
  font-size: 0.97em !important;
  padding-left: 6px;
}
.attachment li:nth-child(2n-1) {
  background-color: #dfdfdf;
  width: 100%;
}

.quadrant {
  position: relative !important;
  display: inline-block !important;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  list-style-position: outside;
  float: left;
}
.quadrant li {
  font-size: 0.97em !important;
  text-align: center;
  margin-left: -32px;
  margin-right: 32px;
}
.uploadForm {
  display: block;
  width: 100% !important;
}
</style>
